import React from 'react'
import Helmet from 'react-helmet'
import { ToastContainer } from 'react-toastify'

import favicon from '../images/favicon.ico'

import { getPageTitle, getPageSlug } from '@theme/utils/stringManipulations'

// import useGatsbyConfig from '@theme/hooks/useGatsbyConfig'
import siteConfig from '@theme/utils/siteConfig'

// Common Components
import Header from '../components/Header'
import Footer from '../components/Footer'

const slugToDescriptionMap = {
  home: 'Conceived as a “First Growth of California," Harlan Estate is a winegrowing estate in the western hills of Oakville, CA in the heart of the Napa Valley.',
  purpose: 'The Vision: To produce a California "First Growth" from the western hills of Oakville.',
  place:
    'Consisting of 240 acres (97 ha) of natural splendor set above the fabled Oakville benchlands, the property rises in elevation from 225 to 1,225 feet (68 to 374 m) above sea level.',
  people:
    'With family and extended family—the team—comes the root system of shared commitments that ties each of us to place, to purpose, to community—and to the promise of a shared future, generation after generation.',
  wine: `Winemaking. "Winemakers believe that the land speaks to them of possibilities. A winemaker's goal is to express those possibilities, to capture the best of what the land has to offer."`,
  allocation:
    'To request an allocation of Harlan Estate wine, kindly submit the form here. For merchants, restaurants, and other members of the trade, inquiries should be addressed to...',
  'bottle-verification': 'Please enter your Bottle ID to authenticate.',
  login: 'Access Account | Forgot Password',
}

const Layout = (props) => {
  const {
    // access,
    // allocationStatus,
    // customer,
    // isRelogged,
    // isLoadingData,
    // settings,
    location,
    children,
  } = props

  const pageSlug = getPageSlug(location.pathname)
  var pageTitle = getPageTitle(location.pathname)
  var siteTitle = siteConfig?.metadata?.title

  if (pageSlug == 'home') {
    pageTitle = siteConfig?.metadata?.title
    siteTitle = `Oakville, CA | Napa Valley`
  } else if (pageSlug == 'place') {
    siteTitle = `Harlan Estate Napa Valley Winery & Vineyards`
  } else if (pageSlug == 'allocation') {
    pageTitle = `Join Allocation`
  } else if (pageSlug == 'login') {
    pageTitle = `Member Login`
  }

  // console.log('pageSlug', pageSlug);

  const isHomePage = location.pathname === '/'

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>
          {pageTitle} | {siteTitle}
        </title>
        <meta name="description" content={slugToDescriptionMap[pageSlug] || ''} />
        <link rel="icon" href={favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=8, minimum-scale=.25, user-scalable=yes"
        />
      </Helmet>

      {/* <ErrorBoundary> */}
      {/* <GeneralModalError /> */}
      {/* <GlobalStyles /> */}
      {/* <StoreProvider> */}

      <div id={`main-page`} className={`${isHomePage ? 'home-page' : ''}`}>
        <ToastContainer
          position="top-left"
          autoClose={5000}
          hideProgressBar
          icon={false}
          style={{ width: '500px', maxWidth: '500px' }}
        />
        <Header {...props} />
        <main className="content-wrapper w-full mx-auto" id={pageSlug}>
          <div className="content-child w-full mx-auto overflow-hidden">{children}</div>
        </main>
        <Footer {...props} />
      </div>
      {/* </StoreProvider> */}
      {/* </ErrorBoundary> */}
    </>
  )
}

export default Layout
